import { graphql } from "gatsby"
import React, { useRef, useState } from "react"
import {
  Banner,
  BlogBlock,
  ErrorBoundary,
  FormSendMailchimp,
  HeroWithImageBlock,
  ImageContent,
  Layout,
  MindBodyPage,
  Seo,
  TextBlocks,
} from "../../index.js"
import PopupBox from "../components/PopupBox/PopupBox.jsx"
import "../style/_customstyles.scss"
export default function Home({ data, pageContext, location }) {
  let { heroVideo, heroTitle, heroSubTitle, button } =
    data.home.blocks[0].heroBlock
  let quoteBlock = data.home.blocks[1].quoteBlock
  let { firstBlock, secondBlock } = data.home.blocks[2].twoImagesBlock
  let familyBlock = data.home.blocks[3].imageContentBlock
  let PtBlock = data.home.blocks[4].imageContentBlock
  let ButtonBlock = data.home.blocks[5].buttonBlock
  let {
    textBlockTitle,
    textBlockSubTitle,
    textBlockTitleStroke,
    buttonText,
    textCard,
  } = data.home.blocks[6]?.textBlock
  let form = data.home.blocks[7]?.formBlock

  let lang = pageContext.lang
  const mindBodyForm = useRef()
  // handle toggle form
  const [toggle, setToggle] = useState(false)
  const onClick = () => {
    const healCode = document.querySelector("healcode-widget")
    if (healCode) {
      healCode.style.display = toggle ? "none" : "block"
    }
    setToggle(!toggle)
  }

  return (
    <Layout pathname={location.pathname}>
      <Seo
        title={data.home.seo?.title}
        description={data.home.seo?.metaDesc}
        featuredImage={
          data.home.seo?.opengraphImage?.localFile.childImageSharp
            .gatsbyImageData
        }
      />
      <Banner onClick={onClick} hidden={toggle} />
      <div ref={mindBodyForm}></div>
      {toggle && (
        <ErrorBoundary>
          <MindBodyPage toggleForm={onClick} toggle={toggle} />
        </ErrorBoundary>
      )}
      <HeroWithImageBlock
        onClick={onClick}
        heroVideo={heroVideo.mediaItemUrl}
        heroTitle={{
          normalTitle: heroTitle,
          strokeTitle: heroSubTitle,
        }}
        heroButton={button}
        className="hero-home"
        TwoImagesContent={{
          isTwoImages: true,
          setting: {
            image1: firstBlock.image,
            image2: secondBlock.image,
          },
          button1: {
            text: firstBlock.buttonText,
            link: firstBlock.buttonLink.uri,
          },
          button2: {
            text: secondBlock.buttonText,
            link: secondBlock.buttonLink.uri,
          },
        }}
        // quote props
        quote={quoteBlock.quoteLeftTitle}
        quoteBy={quoteBlock.quoteLeftBy}
        rightTextTop={quoteBlock.quoteRightTopText}
        rightTextBottom={quoteBlock.quoteRightBottomText}
        quoteActive={quoteBlock.showQuoteBlock}
      />

      <ImageContent
        setting={{
          image: familyBlock.mainImage[0].localFile.childImageSharp.fluid,
          background: familyBlock.backgroundColor,
          titleColor: familyBlock.titleColor,
          textColor: familyBlock.contentColor,
          lightButton: familyBlock.lightButton,
          buttonLink: familyBlock.buttonLink.uri,
        }}
        haveButton={true}
        buttonText={familyBlock.buttonText}
        mainTitle={familyBlock.mainTitle}
        mainContent={familyBlock.mainContent}
      />
      <ImageContent
        setting={{
          image: PtBlock.mainImage[0].localFile.childImageSharp.fluid,
          background: PtBlock.backgroundColor,
          titleColor: PtBlock.titleColor,
          textColor: PtBlock.contentColor,
          lightButton: PtBlock.lightButton,
          isReverse: PtBlock.isReverse,
          lang: lang,
          buttonLink: familyBlock.buttonLink.uri,
        }}
        haveButton={true}
        buttonText={PtBlock.buttonText}
        mainTitle={PtBlock.mainTitle}
        mainContent={PtBlock.mainContent}
      />
      <BlogBlock blogList={data.allWpPost.nodes} button={ButtonBlock} />
      <TextBlocks
        alignCenter={false}
        TextBlock={1}
        textBlockTitle={textBlockTitle}
        textBlockTitleStroke={textBlockTitleStroke}
        textBlockSubTitle={textBlockSubTitle}
        textCard={textCard}
        buttonText={buttonText}
      />
      <FormSendMailchimp
        lang={pageContext.lang}
        form={form}
        formClassName="custom-form"
        buttonClassName="custom-button"
      />
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String!, $lang: String!) {
    home: wpPage(slug: { eq: $slug }, language: { slug: { eq: $lang } }) {
      slug
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      blocks {
        ... on WpAcfHeroBlock {
          heroBlock {
            heroTitle
            heroSubTitle
            heroVideo {
              mediaItemUrl
            }
            button
          }
        }
        ... on WpAcfQuoteBlock {
          quoteBlock {
            quoteLeftBy
            quoteLeftTitle
            showQuoteBlock
            quoteRightBottomText
            quoteRightTopText
          }
        }
        ... on WpAcfTwoImagesBlock {
          twoImagesBlock {
            firstBlock {
              buttonText
              buttonLink {
                ... on WpPage {
                  uri
                }
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(fit: COVER, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            secondBlock {
              buttonText
              buttonLink {
                ... on WpPage {
                  uri
                }
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(fit: COVER, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        ... on WpAcfImageWithContentBlock {
          imageContentBlock {
            mainImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, fit: COVER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundColor
            contentColor
            haveButton
            buttonText
            buttonLink {
              ... on WpPage {
                uri
              }
            }
            isReverse
            lightButton
            mainContent
            mainTitle
            titleColor
          }
        }
        ... on WpAcfButtonBlock {
          buttonBlock {
            buttonText
            buttonLink {
              url
            }
          }
        }
        ... on WpAcfTextBlock {
          textBlock {
            textBlockSubTitle
            textBlockTitle
            textBlockTitleStroke
            buttonText
            textCard {
              textCardContent
              textCardTitle
              textCardImage {
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
          }
        }
        ... on WpAcfSignupFormBlock {
          formBlock {
            signupTitle
            signupSubtitle
            signupButton
          }
        }
      }
    }
    allWpPost(
      sort: { fields: [date] }
      limit: 4
      filter: { language: { slug: { eq: $lang } } }
    ) {
      nodes {
        title
        excerpt
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(quality: 90, fit: COVER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
